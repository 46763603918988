.visual {
    position: relative !important;
    height: 150px;
    margin-bottom: $grid-gutter-width;
    @extend %text-regular;

    .row {
        height: 100%;
    }

    .visual-main {
        position: relative;
        height: 100%;
        padding-right: 0;

        .inner {
            position: relative;
            height: 100%;
            background-size: cover;
            background-color: transparent;
            background-repeat: none;
            outline: 1px solid $gray-lighter;
        }
    }

    .visual-side {
        position: relative;
        height: 100%;
        overflow: hidden;

        .inner {
            position: relative;
            height: 100%;
            overflow: hidden;
        }

        .visual-side-header {
            @extend %box-brand-primary;
            min-height: 150px;
            max-height: 100%;

            // cover overflowing content via gradient div positioned at bottom:
            position: relative;
            @include makeBoxOverflowCover($brand-primary);

            .headline {
                color: #fff;
            }
        }

        .visual-side-body {
            @extend %box-light;
            border-top: ($grid-gutter-width / 2) solid #fff;
            display: none;
        }

        .visual-side-header + .visual-side-body {
            @include makeBoxOverflowCover($gray-lighter);
        }
    }

    &.visual-large {
        height: 400px;

        .inner {
            @extend %colorset-light;
        }

        .visual-side-body {
            display: block;
        }
    }

    .markdown {
        display: block;
    }
}