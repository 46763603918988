/***********************************************************************
* NAVIGATION
***********************************************************************/

.navigation {
    padding: 0;
    @extend %text-navigation;

    a,
    a[rel~="external"],
    span {
        margin: 0;
        padding: 0;
        color: $gray-dark;
        font-weight: bold;
        //font-weight: 700;
        //font-size: 1.2em;
        //background: none;
        text-decoration: none !important;
        //text-transform: uppercase;
    }

    a:active {
        color: $brand-primary !important;
    }

    li {
        position: relative;
        list-style: none;
        margin-bottom: 5px;

        &:hover {
            a {
                color: $gray;
            }
        }

        &.active {
            a:link,
            a:visited,
            a:hover,
            span {
                color: $brand-primary !important;
            }
        }
    }
}

#side-nav {
    .navigation {
        font-size: 14px;
        text-transform: none;
    }
}

.content-context nav {
    margin-bottom: 20px;

    ul,
    ol {
        padding-left: 1.667em;
    }

    li {
        background: url('images/icon-link.gif') left 5px no-repeat transparent !important;
        padding-left: 13px;
    }

    a,
    a:hover {
        font-weight: normal;
    }
}