/***********************************************************************
* CONTENT / TYPOGRAPHY / MARKDOWN
* Markdown syntax see:
* http://daringfireball.net/projects/markdown/syntax
***********************************************************************/

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    @extend %text-headline;
    margin-top: 0;
}

h1, .h1,
h1.headline, .h1.headline {
    margin-bottom: 24px;
    color: $brand-primary;
}

h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6,
p {
    margin-bottom: 12px;
}

blockquote {
    @extend %text-regular;
}

.headline {
    @extend %text-headline;
    //margin-bottom: 0.833em;
    margin-bottom: 16px;
}

.headline-category {
    @extend %text-headline-category;
    margin-bottom: 0 !important;
}

.copyline {
    @extend %text-copyline;
    margin-bottom: 16px;
}

#content {
    @extend %text-regular;
    //
    //h2,
    //h3,
    //h4 {
    //    margin-bottom: 0.833em;
    //    font-size: 1em;
    //}

    p {
        //margin-bottom: 0.833em;
        margin-bottom: 12px;
        //line-height: 1.333em;

        &.right {
            text-align: right;
        }
    }

    h2 + h3,
    blockquote + h2,
    blockquote + h3,
    blockquote + h4,
    ul + h2,
    ul + h3,
    ul + h4,
    ol + h2,
    ol + h3,
    ol + h4,
    p + h2,
    p + h3,
    p + h4 {
        margin-top:1.667em;
    }

    blockquote {
        //margin: 0 0 0.833em 1.667em;
        margin: 0 0 0.833em 0;
    }
}

.markdown {

    //h1 {
    //    //font-size: 18px;
    //    //font-weight: bold;
    //    //color: $brand-primary;
    //    //line-height: 1.2em;
    //    //margin-bottom: 0.833em;
    //    @extend %text-headline;
    //}
    //
    //h2, h3, h4, h5, h6 {
    //    @extend %text-subheadline;
    //}

    //h2,
    //h3,
    //h4,
    //p {
    //    margin-bottom:0.833em;
    //}
    //
    //h2 {
    //    text-transform: uppercase;
    //}
    //
    //p {
    //    line-height:1.333em;
    //}
    //
    //blockquote + h2,
    //blockquote + h3,
    //blockquote + h4,
    //ul + h2,
    //ul + h3,
    //ul + h4,
    //ol + h2,
    //ol + h3,
    //ol + h4,
    //p + h2,
    //p + h3,
    //p + h4 {
    //    margin-top:1.667em;
    //}
}