/***********************************************************************
* BUTTONS
***********************************************************************/

.btn {
    @extend %text-regular;

    &,
    &:hover {
        text-decoration: none;
    }

    a,
    a:hover {
        text-decoration: none;
    }
}

.btn-primary {
    a,
    a:hover {
        color: #fff;
    }
}

.btn-md {
    font-size: 14px;
}

.btn-lg {
    font-size: 18px;
}


.button {
    display: inline-block;
    height: 25px;
    line-height: 25px;
    padding: 0 10px;
    background: url('images/button-bg.png') top left repeat-x transparent;
    //border-radius: 5px;
    //box-shadow: 0 1px 3px lighten($gray-base, 60%); // #999
    border: 1px solid lighten($gray-base, 80%); // #ccc
    text-decoration: none !important;

    a {
        display: inline-block;
        text-decoration: none !important;
    }

    &:hover {
        background: url('images/button-bg-hover.png') top left repeat-x transparent;
        //box-shadow: 0 0 2px lighten($gray-base, 60%); // #999
    }
}

.button-emphasized {
    color: #fff !important;
    font-weight: bold;
    background: url('images/button-emphasized-bg.png') top left repeat-x darken($brand-primary, 10%) !important; // #6f1560 (old: #6e025d)
    border: 1px solid $brand-primary !important;

    a {
        color: #fff !important;
    }

    a.link {
        background:url('images/icon-link-white.gif') left center no-repeat transparent !important;
    }

    a.link-back {
        background:url('images/icon-link-back-white.gif') left center no-repeat transparent !important;
    }

    &:hover {
        background: url('images/button-emphasized-bg-hover.png') top left repeat-x $brand-primary !important;
    }
}