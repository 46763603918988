/***********************************************************************
* GRIDS
***********************************************************************/

.grid {
    margin: 0;
    padding: 0 !important;
}

.grid-item {
    @extend %box-white;
    //@extend %text-small;
    margin-bottom: $grid-gutter-width / 2;
    list-style: none;

    .loadingIcon {
        top: 10px;
        right: 10px;
    }
}

.grid-item-inner {
    position: relative;
}

.product-grid {

    .dj_ie8 & {
        .grid-item-image {
            z-index: 1000;
        }
    }
}

#category-grid {
    position: relative;

    .image {
        position: relative;
        min-height: 100px;
        padding-bottom: percentage(50/300);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    }

    .content {
        position: relative;
        padding: $grid-gutter-width / 2;
        color: $gray;
        background: $gray-lighter;
    }

    h2 {
        margin: 0 0 5px 0 !important;
        font-size: 14px;
    }

    p {
        font-size: 11px;
        margin: 0 !important;
    }

    ul {
        position: relative;
        padding: 0 !important;
        list-style: none;

        &:hover {
            li:not(:hover) {
                opacity: 0.5;
            }
        }
    }

    li {
        position: relative;
        margin: 0 auto ($grid-gutter-width / 2) auto;
        padding: 0;

        > a {
            position: relative;
            display: block;
            text-decoration: none;
            @include clearfix();
        }

        &:hover {
            cursor: pointer;

            .content {
                color: #fff !important;
                background: $brand-primary !important;
            }

            a,
            a:hover {
                color: #fff;
            }
        }
    }

    @media (min-width: $screen-md-min) {

        li {
            height: 100px;

            > a {
                height: 100%;
            }

            .content {
                min-height: 100px;
            }

            .image {
                background-position: ($grid-gutter-width / 2) center;
            }
        }


    }
}