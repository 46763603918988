/***********************************************************************
* QUICKSEARCH BOX WIDGET
***********************************************************************/

#quicksearch {
    white-space: nowrap;

    input {
        display: inline-block;
    }

    input[type="image"] {
        //width: 1em;
        /*height:1.8em;*/
        width: 30px;
        height: 30px;
        margin: 0;
        margin-right: 5px;
        vertical-align: middle;
        //background: url('images/btn-search.gif') center center no-repeat transparent;
        background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4yLjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkViZW5lXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMzBweCIgaGVpZ2h0PSIzMHB4IiB2aWV3Qm94PSIwIDAgMzAgMzAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDMwIDMwIiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxwYXRoIGZpbGw9IiMzMzMzMzMiIGQ9Ik0yMC40NzksMTIuMTg0YzAsNC42MjgtMy43NDksOC4zNzYtOC4zNzYsOC4zNzZjLTQuNjI2LDAtOC4zNzYtMy43NDgtOC4zNzYtOC4zNzZzMy43NS04LjM3Niw4LjM3Ni04LjM3Ng0KCQlDMTYuNzI5LDMuODA4LDIwLjQ3OSw3LjU1NiwyMC40NzksMTIuMTg0IE0yMS44MzYsMTkuMzEyYzEuNDctMi4wMDMsMi4zNjYtNC40NTMsMi4zNjYtNy4xMjdjMC02LjY4Mi01LjQxNy0xMi4wOTktMTIuMDk5LTEyLjA5OQ0KCQljLTYuNjgzLDAtMTIuMDk5LDUuNDE3LTEyLjA5OSwxMi4wOTljMCw2LjY4Miw1LjQxNiwxMi4wOTksMTIuMDk5LDEyLjA5OWMyLjY2MSwwLDUuMS0wLjg4NSw3LjA5Ny0yLjM0MkwyNy4yNTcsMzBsMi42MzQtMi42MzINCgkJTDIxLjgzNiwxOS4zMTJ6Ii8+DQo8L2c+DQo8L3N2Zz4=) center center no-repeat transparent;
        background-size: 50%;
        border: 0;
        cursor: pointer;

        &:hover {
            background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4yLjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkViZW5lXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMzBweCIgaGVpZ2h0PSIzMHB4IiB2aWV3Qm94PSIwIDAgMzAgMzAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDMwIDMwIiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxwYXRoIGZpbGw9IiM5OTFEODUiIGQ9Ik0yMC40NzksMTIuMTg0YzAsNC42MjgtMy43NDksOC4zNzYtOC4zNzYsOC4zNzZjLTQuNjI2LDAtOC4zNzYtMy43NDgtOC4zNzYtOC4zNzZzMy43NS04LjM3Niw4LjM3Ni04LjM3Ng0KCQlDMTYuNzI5LDMuODA4LDIwLjQ3OSw3LjU1NiwyMC40NzksMTIuMTg0IE0yMS44MzYsMTkuMzEyYzEuNDctMi4wMDMsMi4zNjYtNC40NTMsMi4zNjYtNy4xMjdjMC02LjY4Mi01LjQxNy0xMi4wOTktMTIuMDk5LTEyLjA5OQ0KCQljLTYuNjgzLDAtMTIuMDk5LDUuNDE3LTEyLjA5OSwxMi4wOTljMCw2LjY4Miw1LjQxNiwxMi4wOTksMTIuMDk5LDEyLjA5OWMyLjY2MSwwLDUuMS0wLjg4NSw3LjA5Ny0yLjM0MkwyNy4yNTcsMzBsMi42MzQtMi42MzINCgkJTDIxLjgzNiwxOS4zMTJ6Ii8+DQo8L2c+DQo8L3N2Zz4=) center center no-repeat transparent;
            background-size: 50%;
        }
    }

    // quicksearch-term text input

    // prevent jumping after dijit initialization:
    input[type="text"] {

        // input state before dijit init:
        width: 260px;
        height: 30px;
        padding: 5px 10px;
        vertical-align: middle;
        border: 0;
        background: $gray-lighter;
        @include transition(width 0.1s);
        @extend %text-regular;
        color: $gray;

        // input state after dijit init:
        &.dijitInputInner {
            height: 20px;
            padding: 0;
            @extend %text-regular;
            color: $gray;
        }
    }

    // dijit wrapper placed by dojo around text input:
    .dijitTextBox {
        width: 260px;
        padding: 5px 10px;
        vertical-align: middle;
        border: 0;
        background: $gray-lighter;
        @include transition(width 0.1s);
    }

    > input[type="text"]:focus,
    .dijitTextBoxFocused {
        background: $gray-lightest;
        @media (min-width: $screen-md-min) {
            width: 460px;
        }
    }

    .dijitArrowButton {
        display: none;
    }
}

#widget_quicksearch-term_dropdown {
    padding: 5px;

    .dijitMenu {
        border: 1px solid $gray-lighter;
        padding: 0 !important;
        box-shadow: 5px 5px 5px rgba(0,0,0,0.1);
        background: transparent;
    }

    .dijitMenuItem {
        position: relative;
        min-height: 40px;
        padding: 5px 5px 5px 56px;
        color: $gray;
        border-top: 1px solid $gray-lighter;
        white-space: normal;
        background: #fff;
        display: block;
        overflow: hidden;
        //font-size: 11px;
        font-size: 14px;
        //opacity: 0.9;
    }

    #quicksearch-term_popup0 {
        border-top: 0;
    }

    .dijitComboBoxHighlightMatch {
        background: yellow;
    }

    .dijitMenuPreviousButton,
    .dijitMenuNextButton {
        display: none;
    }

    .dijitMenuItemSelected,
    .dijitMenuItemHover {
        background: $gray-lightest; // #fafafa
        opacity: 1;
    }
}

#quicksearch-term_popup_prev,
#quicksearch-term_popup_next {
    display: none;

    &.dijitMenuItemSelected,
    &.dijitMenuItemHover {
        color: $brand-primary;
    }
}

#widget_quicksearch-term_dropdown {

    .dijitMenuItem {

        .image {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            padding: 5px;
            //border-right: 1px solid $gray-lighter;
        }

        p {
            margin: 0;
        }

        a {
            color: $gray-dark;
        }

        .label {
            font-weight: bold;
            text-transform: uppercase;
        }

        .order_no {}

        .price {}
    }

    .dijitMenuItemSelected,
    .dijitMenuItemHover {

        a,
        .label {
            color: $brand-primary;
        }
    }
}