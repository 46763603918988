/***********************************************************************
* SECTION HEADER
***********************************************************************/

/* Site logo */

#header-logo {
    //position: absolute;
    //top: 0;
    //left: 0;
}

/* meta box */

#header-meta {
    //position: absolute;
    //top: 0;
    //right: 0;

    > .inner {
        position: relative;
        display: inline-block;
        float: right;
    }

    span {
        font-size: 11px;
        font-weight: bold;
        color: $gray-dark;
    }

    ul {
        display: inline-block;
        padding: 0;
        margin: 0 0 0 5px;
        vertical-align: middle;
    }

    ul + ul {
        margin: 0 0 0 4px;
        border-left: 1px solid $gray-lighter;
    }

    li {
        display: inline-block;
        margin: 0 0 0 5px;
        list-style: none;
        vertical-align: middle;
    }

    a {
        margin: 0;
        padding: 0;
        font-size: 11px;
        color: $gray;

        &:active,
        &:hover {
            color: $brand-primary;
        }
    }
}

/* Search form */

#header-meta-search {
    display: inline-block;
    vertical-align: middle;
    margin-left: $grid-gutter-width / 2;
}

.dj_ie7 {
    #header-meta > *,
    #header-user > * {
        display: inline !important;
    }
}

/* User greeting / nav */

#header-user {
    position: absolute;
    top: 3.5em;
    right: 0;
    padding: 0.5em 1em;
    background: $gray-lighter;
    border-radius: 0.5em;
}

#header-user-greeting {
    display: inline-block;
    font-size: 1.2em;
    font-weight: bold;
}

#header-user-nav {
    display: inline-block;

    ul {
        padding:0;
        margin:0;
        margin-left:0.5em;
    }

    li {
        display: inline;
        padding: 0 0.5em;
        border-left: 0.1em solid lighten($gray-base, 80%); // #ccc
    }

    a {
        margin: 0;
        padding: 0;
        font-size: 1.1em;
        color: $gray;

        &:hover,
        &:active {
            color: $brand-primary;
        }
    }
}

/* top navigation */

#header-nav {

    margin-top: $grid-gutter-width;

    li {
        display: inline-block;
        float: left;
        margin: 0 $grid-gutter-width 0 0;
        border-bottom: 0.5em solid transparent;

        &:hover {
            border-bottom: 0.5em solid $gray-lighter;
        }

        &.active {
            border-bottom: 0.5em solid $brand-primary;
        }
    }

    a {
        //font-size: 1.3em;
        font-weight: bold;
        text-decoration: none;
    }
}

.header-nav-main {
    position: relative;
    display: inline-block;
    float: left;
    //margin:0 0 -1px 20.5em;
    padding: 0;
    //height: 2.6em;
    //overflow:auto;
    //border-bottom:1px solid $gray-lighter;
    text-transform: uppercase;
}

/* Shop mode links */
.header-nav-right {
    position: relative;
    display: inline-block;
    float: right;
    //position: absolute;
    //top: 0;
    //right: 0;
    //height: 2.6em;
    overflow: hidden;
    margin: 0;
    text-transform: uppercase;

    li {
        float:right !important;
        margin: 0 0 0 1em !important;
    }
}

/* Hubwoo back link */

.header-nav-left {
    position: relative;
    display: inline-block;
    float: left;
    //position: absolute;
    //top: 0;
    //left: 0;
    //width: 17.5em;
    //height: 2.6em;
    //overflow: hidden;
    //margin: 0 0 -1px 0;
    //border-bottom: 1px solid $gray-lighter;
}