// pixel to em conversion
// @see http://vvv.tobiassjosten.net/css/px-to-em-with-sass/
@function em($px, $base: $font-size-base) {
    @return ($px / $base) * 1em;
}

// cover overflowing content via div positioned at bottom of container:
// NOTICE: parent container must have position 'relative' or 'absolute'
@mixin makeBoxOverflowCover($gradient-base-color: $brand-primary, $side: bottom, $heightOrWidth: 40px, $z-index: 10) {

    &:after {
        content: "";
        position: absolute;
        z-index: $z-index;

        // cover placement: bottom of parent container
        @if ($side == 'bottom') {
            height: $heightOrWidth;
            bottom: 0;
            left: 0;
            right: 0;
            @include gradient-vertical(transparent, $gradient-base-color);
        }

        // cover placement: top of parent container
        @else if ($side == 'top') {
            height: $heightOrWidth;
            top: 0;
            left: 0;
            right: 0;
            @include gradient-vertical($gradient-base-color, transparent);
        }

        // cover placement: left side of parent container
        @else if ($side == 'left') {
            width: $heightOrWidth;
            top: 0;
            bottom: 0;
            left: 0;
            @include gradient-horizontal($gradient-base-color, transparent);
        }

        // cover placement: right side of parent container
        @else {
            width: $heightOrWidth;
            top: 0;
            bottom: 0;
            right: 0;
            @include gradient-horizontal(transparent, $gradient-base-color);
        }

    }
}