/***********************************************************************
* CART VIEW
***********************************************************************/

.cart-table {

    .total-row,
    .subtotal-row {
        font-weight: bold;
    }

    .total-row {
        font-size: 1.2em;
    }
}

#cart-content {

    .div-td {
        padding: $grid-gutter-width / 2;
    }

}

#cart-total-box {
    padding-top: $grid-gutter-width / 2;

    .div-th,
    .div-td {
        padding: 5px $grid-gutter-width / 2;
        border-top: 0;
    }

    .total-row {
        font-size: 18px;
        font-weight: bold;
    }
}

.cart-col-article-image {
    display: inline-block;
    float: left;
    margin-right: $grid-gutter-width / 2;
    margin-bottom: $grid-gutter-width / 2;
}









div.table {
    width: 100%;
    border:0;
}

div.table-header,
div.table-data {
    position: relative;
    display: inline-block;
    float: left;
    @include box-sizing(content-box);
}

div.table-header {
    font-weight:bold;
    text-align:left;
    text-transform: uppercase;
    padding: 5px;
}

div.table-data {
    padding: 10px 5px;
}

div.table-row {
    position: relative;
    //overflow: auto;
    border-bottom: 1px solid $gray-lighter; // #ccc
}

div.table-row-header {}

div.table-row-data {
    border-right: 1px solid $gray-lighter; // #ccc
    border-left: 1px solid $gray-lighter; // #ccc
    background: url('images/cart-row-bg.png') top left repeat-x $gray-lighter;
}

div.table-row-data:hover {
    border-right: 1px solid $brand-primary;
    border-left: 1px solid $brand-primary;
    background: #fff;
}

div.table-col {}

div.table-col-position {
    //width: 30px;
    /*text-align: right;*/
}
div.table-col-product {
    //width: 320px;
}

div.table-col-price {
    //width: 80px;
    text-align: right;
}

div.table-col-quantity {
    //width: 100px;
    text-align: center;
    padding-top:7px;
}

div.table-col-total {
    //width: 80px;
    font-weight: bold;
    text-align: right !important;
}

//.btn {
//    display: inline-block;
//    vertical-align: middle;
//    padding: 0;
//}

.btn-plus,
.btn-minus {

    display: inline-block;
    vertical-align: middle;
    padding: 0;


    width: 20px;
    height: 20px;
    line-height: 5px;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    color: $gray-dark !important;
    border: 1px solid $gray-lighter !important; // #ccc
    background: url("images/button-bg.png") repeat-x left top #fff !important;

    &:hover {
        color: #fff !important;
        border: 1px solid $brand-primary !important;
        background: $brand-primary !important;
    }
}

//.btn-plus {
//    border-left: 0 !important;
//    border-radius: 0 5px 5px 0;
//}
//
//.btn-minus {
//    border-right: 0 !important;
//    border-radius: 5px 0 0 5px;
//}

.btn-remove {
    //position: absolute;
    //float: none;
    //top: 7px;
    //right: -10px;
    height: 20px;
    width: 24px;
    margin-left: 10px;
    border: 0 !important;
    background: url('images/button-delete-bg.png') center center no-repeat transparent;
}

.btn-remove:hover {
    background: url('images/button-delete-bg-hover.png') center center no-repeat transparent;
    cursor: pointer;
}

form.cart-item {

    input[type="text"] {
        display: inline-block;
        height: 20px;
        width: 30px;
        padding: 0;
        vertical-align: middle;
        text-align: center;
        border: 1px solid $gray-lighter; // #ccc
        border-radius: 0;
        font-size: 11px;
        line-height: 10px;

        &:focus {
            color: $brand-primary;
            border: 1px solid  $brand-primary;
        }
    }

}

div.table-col-product {
    img {
        position: relative;
        display: inline-block;
        float:left;
        margin:0 10px 0 0;
        border: 1px solid $gray-lighter; // #ccc
    }
}

#cart-footer {
    position: relative;

    .wrapper {
        position: relative;
        //display: inline-block;
        //width: 300px;
        margin: 0;
    }
}

#cart-total-box,
#cart-nav-box {
    position: relative;
    text-align: right;
}

#cart-total-box {

    .wrapper {
        border: 1px solid $gray-lighter; // #ccc
        border-top: 0;
        padding: 10px;
        background: url('images/cart-row-bg.png') top left repeat-x $gray-lighter;
    }

    table {
        width: 100%;
        margin:0 !important;
    }

    th,
    td {
        padding: 3px 0;
    }

    th {
        font-weight: normal;
    }

    td {
        text-align: right;
    }

    .total-row {

        th,
        td {
            font-size: 1.2em;
            font-weight: bold;
            border-top: 3px double #000;
        }

        th {
            font-weight: bold;
        }
    }
}

#cart-shippingcost-box {
    position: relative;
    margin-top: $grid-gutter-width;
    margin-bottom: $grid-gutter-width;

    table {
        width: 100%;
    }

    p {
        font-size: 11px;
        margin-bottom: 0;
    }

    .wrapper {
        display: inline-block;
        margin-top: 10px;
        padding: 10px;
        border: 1px solid $gray-lighter; // #ccc
        text-align: left;
    }
}

#cart-nav-box {
    position: relative;
    margin-top: $grid-gutter-width;
    text-align: right;

    a {
        //width: 105px;
        text-align: left;
    }

    .btn + .btn {
        margin-left: $grid-gutter-width / 2;
    }
}