/***********************************************************************
* BASE
***********************************************************************/

html,
body {
    position: relative;
    height: 100%;
    min-height: 100%;
}

//body {
//    font-family: $font-family-sans-serif;
//    font-size: 10px;
//    margin:0;
//    padding:0;
//    color: #333;
//    background: #DDDCDA;
//}
//
//::-moz-selection {
//    color: #fff;
//    background: $brand-primary;
//}
//
//::selection {
//    color: #fff;
//    background: $brand-primary;
//}

aside + aside {
    margin-top: $grid-gutter-width;
}

/***********************************************************************
* INTERFACE / SECTIONS
***********************************************************************/

#wrapper {
    position: relative;
    //width: 98em;
    margin: 0 auto;
    padding: 0;
    min-height: 100%;
    background: #fff;
    overflow: auto;

    .dj_ie7 & {
        overflow: visible;
        zoom: 1;
    }
}

#main {
    position: relative;
    margin-bottom: 8em;
    //margin: 0 2em 8em 9em;
}

#footer {
    //position: absolute;
    //bottom: 1em;
    //left: 29.5em;
    //width: 66.5em;
    //border-top: 0.1em solid darken($gray-lighter, 7%);
    //padding: 1em 0 0 0;
    //color: lighten($gray-base, 67.5%); // #acacac (old: #aca49d)
    margin: $grid-gutter-width 0;
}

#side {
    position: relative;
    display: inline-block;
    float: left;
    //width: 17.5em;
}

#header {
    position: relative;
    //margin: $grid-gutter-width $grid-gutter-width 0 $grid-gutter-width;
    margin-top: $grid-gutter-width;
    //margin-bottom: ($grid-gutter-width / 2);
    //height: 13.5em;
}




/***********************************************************************
* SECTION SIDE
***********************************************************************/

/* cart box */

/* category navigation */

#side-nav {
    margin: 0 0 4em 0;
}

/* info boxes */

#side {

    p {
        font-size: 1.1em;
        margin-bottom: 0.45em;
    }

    .box {
        margin-bottom: 2em;
    }

    .headline {
        font-size: 1.1em;
        font-weight: bold;
        margin: 0;
    }
}


/***********************************************************************
* SECTION CONTEXT
***********************************************************************/

.content-context {



    p {
        //font-size: 1.1em;
        margin-bottom: 0.45em;
    }

    //.box {
    //    padding: 5px;
    //    margin-bottom: 10px;
    //    overflow: auto;
    //
    //    h2,
    //    h3,
    //    h4,
    //    h5,
    //    h6,
    //    p {
    //        margin-top: 0 !important;
    //        margin-bottom: 0.45em !important;
    //
    //        &:last-child {
    //            margin-bottom: 0 !important;
    //        }
    //    }
    //
    //    ul {
    //        margin-left: 1.2em !important;
    //        overflow: visible;
    //    }
    //
    //    table {
    //        margin-bottom: 0 !important;
    //    }
    //}

    //.box-grey {
    //    background: lighten($gray-base, 95.3%); // #f3f3f3
    //}

    //.headline {
    //    font-size: 1.1em;
    //    font-weight: bold;
    //    margin: 0;
    //}
}

#box-pom {
    .headline {
        margin-bottom: $grid-gutter-width / 2;
        padding: $grid-gutter-width / 2;
        font-size: 1.2em !important;
        color: #fff !important;
        background: $brand-primary;
        text-transform: uppercase;
    }

    .product-image {
        position: relative;
        padding-bottom: 100%;
        background-size: cover;
        background-color: transparent;
        background-repeat: none;
        border: 0.1em solid $gray-lighter;

        &:hover {
            cursor: pointer;
        }
    }

    //.product-title {
    //    @extend %box-light;
    //    //padding: $grid-gutter-width / 2;
    //    //font-size: 1.2em;
    //    //font-weight: bold;
    //    text-transform: uppercase;
    //    //background: $gray-lighter;
    //}
}


/***********************************************************************
* SECTION CONTENT BREADCRUMBS
***********************************************************************/

#breadcrumbs {
    position: relative;
    //margin: 2em 0 1em 20.5em;
    //margin: 2em 0 1em 20.5em;

    nav {
        font-size:1.1em;
        display: none;
    }
}


/***********************************************************************
* SECTION CONTENT VISUAL
***********************************************************************/

#content {
    position: relative;
    //display: inline-block;
    //float: right;
    //padding:0 0 8em 20.5em;
    //width:66.5em;
    //width: 55.417em;

    td {
        padding: 2px;
    }

    .dots {
        padding-top: 10px;
        padding-bottom: 10px;
        border-width: 2px 0;
        border-style: dotted;
        border-color: $gray;
    }

    ul,
    ol {
        padding: 0;
        padding-left: 2.75em;
        margin: 0;
        margin-bottom: 0.417em;
        overflow: auto;
    }

    li {
        margin-bottom: 5px;
        //margin-bottom: 0.417em;
        //line-height:1.333em;

        table {
            margin-top:5px;

            //.left {
            //    text-align:left;
            //    width: 150px;
            //}
            //
            //.right {
            //    text-align:right;
            //}
        }
    }

    nav {
        margin-bottom:1.667em;

        ul,
        ol {
            padding-left: 1.667em;
        }

        li {
            background: url('images/icon-link.gif') left 5px no-repeat transparent !important;
            padding-left: 13px;
        }
    }

    .section {
        //border-bottom: 1px solid lighten($gray-base, 74.9%); // #bfbfbf
    }

    .content-inner {
        //width:38.333em;
        //width: 460px;
    }

    .content-context {
        position: relative;
        display: inline-block;
        //float: right;
        //width:18.5em;
        //width:15.417em;
        //width: 185px;
        line-height: 1.4em;
        //margin-left: 1.667em;

        ul,
        ol {
            padding: 0 !important;
        }
    }

    &.full {
        .content-inner {
            width: 100% !important;
        }
    }

    .dj_ie & {
        .content-inner {
            overflow: hidden;
        }
    }

    .row {
        //position: relative;
        //overflow: auto;
        //margin: 0 -20px 0 0;
    }

    .column {
        position: relative;
        float: left;
        display: inline-block;
        width: 200px;
        margin: 0 20px 20px 0;
    }
}


/***********************************************************************
* CONTENT / BOXES & STYLES
***********************************************************************/

.shadow-box {
    position: relative;
    //border-left: 10px solid lighten($gray-base, 80%); // #ccc
    //border-radius: 5px;
    box-shadow: 0 2px 5px darken($gray-lighter, 7%);
    border: 1px solid darken($gray-lighter, 7%);
}

.content-box-small {
    padding: 20px !important;
    background: #fff;

    input[type="text"],
    input[type="password"],
    textarea {
        width: 254px;
    }

    select {
        width: 266px;
    }
}

.content-box-grey {
    padding: $grid-gutter-width / 2;
    background: $gray-lightest; // #f3f3f3

    // min-height for embedded floating buttons:
    min-height: $grid-gutter-width * 2;
}


/***********************************************************************
* LINKS
***********************************************************************/

//a {
//    color: $brand-primary;
//    text-decoration: none;
//}
//
//a:link, a:visited {
//    color: $brand-primary;
//}
//
//a:hover, a:active {
//    color: $brand-primary;
//    text-decoration: underline;
//}

a.link {
    //padding-left: 1em !important;
    //background: url('images/icon-link.gif') left center no-repeat transparent !important;
}

a.link-back {
    padding-left: 1em !important;
    background: url('images/icon-link-back.gif') left center no-repeat transparent !important;
}

a.link-external {
    padding-left: 1.2em !important;
    background: url('images/icon-link-extern.gif') left center no-repeat transparent !important;
}

a.link-pdf {
    padding-left: 1.2em !important;
    background: url('images/icon-pdf.gif') left center no-repeat transparent !important;
}

a.link-shop {
    padding-left: 1.4em !important;
    background: url('images/icon-cart.gif') left center no-repeat transparent !important;
}

a.link-more {
    text-transform: uppercase;
    white-space: nowrap;
    &:before {
        content: '... ';
    }
}


/***********************************************************************
* VCARD
***********************************************************************/

.vcard {

    .email,
    .fax,
    .tel,
    .url {
        padding: 0 0 0 1.45em;
    }

    .tel {
        background: url('images/icon-phone.gif') left center no-repeat transparent;
    }

    .fax {
        background: url('images/icon-fax.gif') left center no-repeat transparent;
    }

    .email {
        background: url('images/icon-email.gif') left center no-repeat transparent;
    }

    .url {
        background: url('images/icon-link-extern.gif') left center no-repeat transparent;
    }
}


/***********************************************************************
* MISC
***********************************************************************/

.purple {
    color: $brand-primary;
}