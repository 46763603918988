/***********************************************************************
* FONTS
***********************************************************************/

/** FONT AWESOME **/
$fa-font-path: 'fonts/font-awesome/fonts/' !default;
@import 'fonts/font-awesome/scss/font-awesome.scss';

/** EVONIK PROKYON REGULAR **/
@font-face {
    font-family: EvonikProkyon;
    font-weight: 400;
    font-style: normal;
    src:    url("fonts/EvonikProkyon-Regular.eot");
    src:    url("fonts/EvonikProkyon-Regular.eot?#iefix") format("embedded-opentype"),
            url("fonts/EvonikProkyon-Regular.woff2") format("woff2"),
            url("fonts/EvonikProkyon-Regular.woff") format("woff"),
            url("fonts/EvonikProkyon-Regular.ttf") format("truetype"),
            url("fonts/EvonikProkyon-Regular.svg#EvonikProkyon-Regular") format("svg");
}

/** EVONIK PROKYON ITALIC **/
@font-face {
    font-family: EvonikProkyon;
    font-weight: 400;
    font-style: italic;
    src:    url("fonts/EvonikProkyon-LightItalic.eot");
    src:    url("fonts/EvonikProkyon-LightItalic.eot?#iefix") format("embedded-opentype"),
    url("fonts/EvonikProkyon-LightItalic.woff2") format("woff2"),
    url("fonts/EvonikProkyon-LightItalic.woff") format("woff"),
    url("fonts/EvonikProkyon-LightItalic.ttf") format("truetype"),
    url("fonts/EvonikProkyon-LightItalic.svg#EvonikProkyon-LightItalic") format("svg");
}

/** EVONIK PROKYON MEDIUM **/
@font-face {
    font-family: EvonikProkyon;
    font-weight: 500;
    font-style: normal;
    src:    url("fonts/EvonikProkyon-Medium.eot");
    src:    url("fonts/EvonikProkyon-Medium.eot?#iefix") format("embedded-opentype"),
            url("fonts/EvonikProkyon-Medium.woff2") format("woff2"),
            url("fonts/EvonikProkyon-Medium.woff") format("woff"),
            url("fonts/EvonikProkyon-Medium.ttf") format("truetype"),
            url("fonts/EvonikProkyon-Medium.svg#EvonikProkyon-Medium") format("svg");
}

/** EVONIK PROKYON BOLD **/
@font-face {
    font-family: EvonikProkyon;
    font-weight: 700;
    font-style: normal;
    src:    url("fonts/EvonikProkyon-Bold.eot");
    src:    url("fonts/EvonikProkyon-Bold.eot?#iefix") format("embedded-opentype"),
            url("fonts/EvonikProkyon-Bold.woff2") format("woff2"),
            url("fonts/EvonikProkyon-Bold.woff") format("woff"),
            url("fonts/EvonikProkyon-Bold.ttf") format("truetype"),
            url("fonts/EvonikProkyon-Bold.svg#EvonikProkyon-Bold") format("svg");
}

/** EVONIK PROKYON-LIGHT NORMAL **/
@font-face {
    font-family: EvonikProkyon;
    font-weight: 200;
    font-style: normal;
    src:    url("fonts/EvonikProkyon-Light.eot");
    src:    url("fonts/EvonikProkyon-Light.eot?#iefix") format("embedded-opentype"),
            url("fonts/EvonikProkyon-Light.woff2") format("woff2"),
            url("fonts/EvonikProkyon-Light.woff") format("woff"),
            url("fonts/EvonikProkyon-Light.ttf") format("truetype"),
            url("fonts/EvonikProkyon-Light.svg#EvonikProkyon-Light") format("svg");
}

/** EVONIK PROKYON-LIGHT ITALIC **/
@font-face {
    font-family: EvonikProkyon;
    font-weight: 200;
    font-style: italic;
    src:    url("fonts/EvonikProkyon-LightItalic.eot");
    src:    url("fonts/EvonikProkyon-LightItalic.eot?#iefix") format("embedded-opentype"),
            url("fonts/EvonikProkyon-LightItalic.woff2") format("woff2"),
            url("fonts/EvonikProkyon-LightItalic.woff") format("woff"),
            url("fonts/EvonikProkyon-LightItalic.ttf") format("truetype"),
            url("fonts/EvonikProkyon-LightItalic.svg#EvonikProkyon-LightItalic") format("svg");
}

/** EVONIK ANTARES NORMAL **/
@font-face {
    font-family: EvonikAntares;
    font-weight: normal;
    font-style: normal;
    src:    url("fonts/EvonikAntares-Regular.eot");
    src:    url("fonts/EvonikAntares-Regular.eot?#iefix") format("embedded-opentype"),
            url("fonts/EvonikAntares-Regular.woff2") format("woff2"),
            url("fonts/EvonikAntares-Regular.woff") format("woff"),
            url("fonts/EvonikAntares-Regular.ttf") format("truetype"),
            url("fonts/EvonikAntares-Regular.svg#EvonikAntares-Regular") format("svg");
}

/** EVONIK ANTARES ITALIC **/
@font-face {
    font-family: EvonikAntares;
    font-weight: normal;
    font-style: italic;
    src:    url("fonts/EvonikAntares-Italic.eot");
    src:    url("fonts/EvonikAntares-Italic.eot?#iefix") format("embedded-opentype"),
            url("fonts/EvonikAntares-Italic.woff2") format("woff2"),
            url("fonts/EvonikAntares-Italic.woff") format("woff"),
            url("fonts/EvonikAntares-Italic.ttf") format("truetype"),
            url("fonts/EvonikAntares-Italic.svg#EvonikAntares-Italic") format("svg");
}

/** EVONIK ANTARES BOLD **/
@font-face {
    font-family: EvonikAntares;
    font-weight: bold;
    font-style: normal;
    src:    url("fonts/EvonikAntares-Medium.eot");
    src:    url("fonts/EvonikAntares-Medium.eot?#iefix") format("embedded-opentype"),
            url("fonts/EvonikAntares-Medium.woff2") format("woff2"),
            url("fonts/EvonikAntares-Medium.woff") format("woff"),
            url("fonts/EvonikAntares-Medium.ttf") format("truetype"),
            url("fonts/EvonikAntares-Medium.svg#EvonikAntares-Medium") format("svg");
}

/** EVONIK ANTARES BOLD ITALIC **/
@font-face {
    font-family: EvonikAntares;
    font-weight: bold;
    font-style: italic;
    src:    url("fonts/EvonikAntares-MediumItalic.eot");
    src:    url("fonts/EvonikAntares-MediumItalic.eot?#iefix") format("embedded-opentype"),
            url("fonts/EvonikAntares-MediumItalic.woff2") format("woff2"),
            url("fonts/EvonikAntares-MediumItalic.woff") format("woff"),
            url("fonts/EvonikAntares-MediumItalic.ttf") format("truetype"),
            url("fonts/EvonikAntares-MediumItalic.svg#EvonikAntares-MediumItalic") format("svg");
}