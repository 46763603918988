/***********************************************************************
* BOXES
***********************************************************************/

.box {
    :last-child {
        margin-bottom: 0 !important;
    }

    & + .box {
        margin-top: $grid-gutter-width / 2;
    }
}

.box-brand-primary {
    @extend %box-brand-primary;
}

.box-light {
    @extend %box-light;
}

.box-white {
    @extend %box-white;
}