/***********************************************************************
* PAGERS
***********************************************************************/

.pager {
    position: relative;
    overflow: auto;
    margin-bottom: 2em;
    text-align: center;
}

.pager-inner {
    display: inline-block;
    padding: 0 6em;
    text-align: left;
}

.pager-link-prev,
.pager-link-next {
    position: absolute;
    width: 5em;
    top: 0;
    line-height: 1.8em;
}
.pager-link-prev {
    left: 0;
    text-align: left;
}
.pager-link-next {
    right: 0;
    text-align: right;
}

.pager-label {
    position: relative;
    float: left;
    display: inline-block;
    line-height: 1.8em;
    margin-right: 0.8333em;
}

.pager-items {
    display: inline-block;
    max-width: 14em;
}

.pager-item {
    position: relative;
    float: left;
    display: inline-block;
    width:1.8em;
    margin: 0 0.2em 0.2em 0;
    color: #000;
    background: #fff;
    font-size: 1em;
    text-align: center;
    line-height: 1.8em;
    background: #fff;
    border-radius: 0.9em;

    a {
        text-decoration: none !important;
    }

    &:hover {
        color: $gray !important;
        background: lighten($gray-base, 86.7%); // #ddd
        cursor:pointer;

        a {
            color: $gray;
        }
    }
}

.pager-item-active,
.pager-item-active:hover {

    color: #fff !important;
    font-weight: bold;
    background: $brand-primary;

    a {
        color: #fff !important;
    }
}