/***********************************************************************
* STOREFRONT / HOME
***********************************************************************/

#featured-products-container {
    margin-bottom: $grid-gutter-width * 2;
}

//.storefront {
//
//    .content-box-grey {
//        /*padding-bottom: 45px;*/
//        margin-bottom: 20px;
//
//        p {
//            font-size: 11px;
//            color: $gray;
//        }
//
//        .button {
//            width: 130px;
//        }
//
//        //.button-container {
//        //    position: absolute;
//        //    left: 10px;
//        //    bottom: 10px;
//        //}
//    }
//}