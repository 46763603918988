/***********************************************************************
* PRODUCT
***********************************************************************/

.product {

    .title {
        //margin-bottom: 0 !important;
        //font-size: 1.5em;
        //font-weight: bold;
        //color: #000;
        @extend %text-subheadline;
    }

    .teaser {}

    .description {}

    .order_no {
        margin-bottom: 1em !important;
        font-size: 1em;
        font-weight: bold;
        color: #000;
    }

    .price {
        display: block;
        color: $brand-primary;
        font-size: 1.6em;
        //font-weight: bold;
    }

    .price_info {
        display: block;
        margin-top: 0.6em;
        color: $gray-light; // #999
        font-size: 0.9091em;
        line-height: 1em;
    }

    .price_old {
        display: block;
        margin-bottom:0.5em;
    }

    .notice {
        padding: 5px 5px 5px 27px;
        //border: 1px solid #CC0000;
        //background: url('images/icons/silk/lightbulb.png') 5px 5px no-repeat white;
        color: darken($brand-primary, 20%);
        background: url('images/icons/silk/lightbulb.png') 5px 5px no-repeat lighten($brand-primary, 60%);
    }

    .delivery_delay {
        > span {
            display: inline-block;
            clear: both;
        }
    }

    .delivery_delay_message,
    .delivery_delay_estimated_date,
    .delivery_delay_link {
        margin-bottom: 0 !important;
    }

    .delivery_delay_message {
        padding:2px 5px;
        text-transform: uppercase;
        color: $gray-dark;
        background: yellow;
    }

    .delivery_delay_message a {
        color: $gray-dark !important;
        text-decoration: none !important;
    }

    .delivery_delay_estimated_date {}

    .delivery_delay_link {}

    // box containing product description:
    .details {
        margin-bottom: $grid-gutter-width;
    }

    // box containing technical product data:
    .specs {}

    .url {}

    .image {}

}


/***********************************************************************
* PRODUCT DETAIL VIEW
***********************************************************************/

.product-detail {
    /*height: 470px;
    overflow: hidden;*/
    position: relative;
    margin-bottom: $grid-gutter-width;
    /*padding-bottom:60px !important;*/

    .copyline {
        margin-top: 0;
        border-bottom: 1px solid #DDDCDA;
        font-size: 1em;
    }

    .section {
        position: relative;
        margin-bottom: $grid-gutter-width;
    }

    .teaser {
        font-size: 18px;
    }

    .description {
        padding-right: 10px;
        overflow: auto;
    }

    .images {
        position: relative;
        //overflow: auto;
        /*height:621px;*/
        //height: 458px;
        padding-bottom: 100%;
        //margin-bottom: 20px;
        margin-bottom: $grid-gutter-width * 2;
        border: 1px solid $gray-lighter;
        //border: 1px solid darken($gray-lighter, 7%);
        //box-shadow: 0 2px 5px darken($gray-lighter, 7%);
        /*position: absolute;
        width: 470px;
        height: 470px;
        overflow:hidden;*/
    }

    .specs {

        table {
            width: 100%;
            margin: 0;
            margin-bottom: $grid-gutter-width !important;
            padding: 0;
            /*font-size: 11px;*/
        }

        th {
            width: 120px;
            padding: 2px 5px;
        }

        td {
            padding: 2px 5px;
        }
    }

    form.add2cart {
        .container-selector select {
            width: 348px;
            font-size: 12px;
        }
    }
}

.product-special-badge {
    margin-bottom: 5px;
    padding: 2px 5px;
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
    color: #fff;
    background: $brand-primary;
}
span.product-special-badge {
    display: inline-block;
}

/***********************************************************************
* ADD TO CART WIDGET
***********************************************************************/

form.add2cart {

    input,
    select {
        padding: 0;
        border: 0;
        background: none;
        font-size: 10px;
        @include box-sizing(content-box);
    }

    .dj_ie7 & {
        select {
            margin-top: 2px;
        }
    }

    option {
        color: #000;
    }

    .add2cart-container {
        position: relative;
        display: inline-block;
        float: left;
        white-space: nowrap;
        font-size: 10px;
        color: #fff;
        background: $brand-primary;
        border: 1px solid lighten($gray-base, 86.7%); // #ddd (old: #dadada)
    }

    .container-selector,
    .container-quantity,
    .container-submit {
        position: relative;
        display: inline-block;
        float: left;
        height: 24px;
        line-height: 24px;
        padding: 0 5px;
    }

    .container-selector,
    .container-submit {
        color: #000;
        //background: url("images/button-bg.png") repeat-x left top #fff;
        background: #fff;
    }

    .container-selector {

        select {
            color: $brand-primary;
            //width: 220px;
            width: 208px;
            font-size: 11px;
        }

    }

    .container-quantity {
        padding: 0;

        .hint {
            position: absolute;
            top: 0;
            right: 12px;
            height: 24px;
            line-height: 24px;
            text-align: center;
        }

        input,
        input:focus {
            width: 30px;
            height: 24px;
            padding-right: 19px;
            color: #fff;
            font-size: 12px;
            text-align: right;
            background: none;
            border: 0 !important;
        }

        input:hover,
        input:focus {
            background: $brand-primary;
        }
    }

    .container-submit {

        input {
            width: 40px;
            height: 24px;
            background: url("images/button-add2cart-icon.png") 5px center no-repeat transparent;
            cursor: pointer;

            &[disabled] {
                background: url("images/button-add2cart-disabled-icon.png") 5px center no-repeat transparent !important;
                cursor: text !important;
            }

        }
    }
}