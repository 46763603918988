/***********************************************************************
* MESSAGE BOX WIDGET
***********************************************************************/

.message-box-error,
.message-box-success {
    margin-bottom: $grid-gutter-width / 2;
    padding: $grid-gutter-width / 2;
    font-size: 14px;

    p:last-child {
        margin-bottom: 0 !important;
    }
}

.message-box-error {
    color: $brand-danger;
    background: lighten($brand-danger, 40%);  // #fdf7f7

    .headline {
        color: $brand-danger;
    }
}

.message-box-success {
    color: $brand-success;
    background: lighten($brand-success, 40%);

    .headline {
        color: $brand-success;
    }
}