/***********************************************************************
* INCLUDES
***********************************************************************/
@import url(../../CC/resources/_default.helpers.css);

// Core variables and mixins
@import "variables";
@import "../../../../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables";
@import "../../../../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins";
@import "mixins";

// Reset and dependencies
@import "../../../../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/normalize";

// Core CSS
@import "../../../../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/scaffolding";
@import "../../../../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/type";
@import "../../../../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/code";
@import "../../../../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/grid";
@import "../../../../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/tables";
@import "../../../../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/forms";
@import "../../../../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/buttons";
@import "fonts";
@import "placeholders";
@import "dijit";

// app components
@import "base";
@import "type";
@import "nav";
@import "buttons";
@import "boxes";
@import "tables";
@import "forms";
@import "grids";
@import "pagers";
@import "visuals";
@import "cartbox";
@import "messagebox";
@import "quicksearch";
@import "widgets";

@import "header";
@import "footer";

// app views
@import "storefront";
@import "category";
@import "product";
@import "cart";
@import "order";
