@import url(../../dijit/themes/dijit.css);
@import url(../../CC/Widget/resources/DialogConfirm.css);

/* Dialog */
.dijitDialogPaneContent {
    font-size: 1.2em;
}


.dijitTabContainerBottom-container,
.dijitTabContainerTop-container,
.dijitTabContainerLeft-container,
.dijitTabContainerRight-container {
    border: 0 none;
}

.dijitTabPane,
.dijitStackContainer-child,
.dijitAccordionContainer-child {
    border: 0 none !important;
}

.dijitTabContainerTop-tabs {
    border-bottom: 1px solid #CCCCCC;
}

.dijitTabInnerDiv {
    margin-right: 10px;
}

.dijitTabButtonIcon {
    display: none;
}

.tabLabel {
    color: $gray;
    font-size: 11px;
    text-transform: uppercase;

    &:hover {
        color: #000;
    }
}

.dijitTabChecked .tabLabel {
    color: $brand-primary !important;
}

.dijitTabContainerTop-spacer {
    height: 15px;
}

.dijitContentPaneLoading,
.dijitContentPaneError {
    display: block;
    left: 0;
    margin-top: -10px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
    width: 100%;

    span {
        display: inline-block;
        line-height: 20px;
        padding-left: 30px;
        white-space: nowrap;
    }

}

.dijitContentPaneError SPAN {
    background: url("images/icons/silk/exclamation.png") no-repeat scroll left center transparent;
    padding-left: 26px !important;
}

.dijitButton {

    .dijitButtonNode {
        background: url("images/sprites-btn.png") no-repeat scroll right -160px transparent;
    }

    .dijitButtonContents {
        background: url("images/sprites-btn.png") repeat-x scroll right -200px transparent;
    }
}

.dijitButton-emphasized {
    background: url("images/sprites-btn.png") no-repeat scroll left -480px transparent;

    .dijitButtonNode {
        background: url("images/sprites-btn.png") no-repeat scroll right -520px transparent;
    }

    .dijitButtonContents {
        background: url("images/sprites-btn.png") repeat-x scroll right -560px transparent;
    }

    &:hover {
        background: url("images/sprites-btn.png") no-repeat scroll left -600px transparent;

        .dijitButtonNode {
            background: url("images/sprites-btn.png") no-repeat scroll right -640px transparent;
        }

        .dijitButtonContents {
            background: url("images/sprites-btn.png") repeat-x scroll right -680px transparent;
        }
    }
}