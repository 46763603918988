/***********************************************************************
* FORMS
***********************************************************************/

form {
    @extend %text-regular;

    fieldset {
        margin: 0;
        margin-bottom: 20px;
        padding: 0;
        border: 0;
    }

    .legend {
        @extend %text-headline;
        margin-bottom: 5px;
        padding-bottom: 5px;
        border-bottom: 1px solid $gray-lighter; // #bfbfbf

        //font-size: 12px;
        //font-weight: bold;
        //margin-bottom: 10px;
        //text-transform: uppercase;
    }

    label {
        font-weight: normal;

        &.required {
            font-weight: bold;
        }
    }

    .form-control {
        @extend %text-regular;
        height: auto;
        @include box-shadow(none);
        //padding: 4px 12px;

        &:focus {
            @include box-shadow(none);
            border-color: inherit;
        }

        &[disabled] {
            background-color: transparent;
            border-color: transparent;
        }

        &[readonly],
        &[readonly]:focus {
            padding: 0;
            //height: 24px;
            //line-height: 24px;
            //border: 0;
            border-color: transparent;
            @include box-shadow(none);
            //color: #000;
            background: transparent;
        }
    }

    input[type="button"],
    input[type="submit"] {
        display: inline-block;
    }

    input[type="image"] {
        padding: 0;
    }

    input[type="checkbox"],
    input[type="radio"] {
        vertical-align: text-top;

        & + label {
            display: inline;
            margin-left: 5px;
            vertical-align: text-top;
        }
    }

    textarea.form-control {
        &[readonly],
        &[readonly]:focus {
            padding: 6px 0;
            //border: 0;
            border-color: transparent;
            @include box-shadow(none);
            background-color: transparent;
        }
    }

    .field-length-5 {
        width: 100px !important;
    }

    .field-length-10 {
        width: 150px !important;
    }

    .captcha {

        img {
            display: block;
            margin-bottom: 5px;
        }

        //.field-captcha {
        //    width: 194px;
        //    display: block;
        //}
    }

    .form-description,
    .subform-description {
        color: $gray;
        font-style: italic;
        margin-bottom: 20px;
    }

    .field-description {
        color: $gray; // #999
        margin-top: 5px !important;
    }

}

//form .form-description,
//form .subform-description {
//    color: $gray;
//    font-size: 11px;
//    font-style: italic;
//    margin-bottom: 20px;
//}


//label {
//    position: absolute;
//    top: 0;
//    left: 0;
//    width: 130px;
//    padding-top: 3px;
//}

//label.required {
//    font-weight: bold;
//}

//.form-row.form-checkbox label {
//    position: relative;
//    display: inline-block;
//    /*width:auto;*/
//    width: 260px;
//    margin-left: 5px;
//    padding-top: 0;
//    vertical-align: top;
//}

//.field-description {
//    color: lighten($gray-base, 60%); // #999
//    //font-size: 11px;
//    margin-top: 5px !important;
//}

input,
textarea,
select {
    //padding: 2px 5px;
    //color: $gray-dark;
    //font-family: $font-family-sans-serif !important;
    //font-size: 12px;
    ////border: 1px solid lighten($gray-base, 74.9%); // #bfbfbf
    //border: 1px solid lighten($gray-base, 86.7%); // #ddd
    //background: $gray-lighter;
    //
    //&:focus {
    //    color: $brand-primary;
    //    //border: 1px solid $brand-primary;
    //    border: 1px solid lighten($gray-base, 80%); // #ccc
    //    background: #fff;
    //}
}



//input[type="text"],
//input[type="password"],
//select,
//textarea {
//    width: 320px;
//}

//select {
//    padding: 2px;
//}


//
//input[type="checkbox"],
//input[type="radio"] {
//    vertical-align: middle;
//}



.field-error {

    label {
        color: $brand-danger;
    }

    input,
    textarea,
    select {
        color: $brand-danger;
        background: lighten($brand-danger, 40%);
        border-color: lighten($brand-danger, 30%);
    }

    a {
        color: $brand-danger;
    }
}

ul.errors {
    margin: 5px 0 0 0 !important;
    padding: 0 !important;

    li {
        min-height: 16px;
        margin-bottom: 5px;
        padding: 0 0 0 20px;
        background: url(images/icons/silk/exclamation.png) left top no-repeat transparent;
        list-style: none;
        color: $brand-danger;
        font-size: 11px;
    }
}