/***********************************************************************
* SECTION FOOTER
***********************************************************************/

#footer-nav {
    display: inline-block;

    ul {
        display: inline-block;
        padding: 0;
        margin: ($grid-gutter-width / 2) 0;
    }

    li {
        display: inline-block;
        float: left;
        padding: 0 0.7em 0 0;
        margin: 0;
        list-style: none;

        &.active a {
            color: $gray-dark !important;
            font-weight: bold;
        }
    }

    li + li {
        padding: 0 0.7em 0 0.7em;
        border-left: 0.1em solid lighten($gray-base, 67.5%); // #acacac (old: #aca49d)
    }

    a {
        font-size: 1.4em;
        color: $gray-darker;
        //color: lighten($gray-base, 67.5%); // #acacac (old: #aca49d)

        &:hover,
        &:active {
            color: $brand-primary;
            text-decoration: none;
        }
    }
}