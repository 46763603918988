#side-cartbox {
    //padding: ($grid-gutter-width / 2);
    //margin: 0 0 2em 0;
    //background: $gray-lightest;
    //color: $gray;

    a {
        display: block;
        font-size: 1.1em;
        line-height: 1.5em;

        &:hover {
            text-decoration: none;
        }
    }

    .title {
        font-size: 1.2em;
        //font-weight: bold;
        font-weight: normal;
        text-transform: uppercase;
        margin-bottom: ($grid-gutter-width / 2);

        // cart icon:
        i {
            color: $brand-primary;
            font-size: 150%;
        }
    }

    .content {
        padding: 0.5em;
        background: #fff;
    }
}