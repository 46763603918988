/***********************************************************************
* PLACEHOLDERS
***********************************************************************/

/***************************** COLORSETS ******************************/

%colorset-brand-primary {
    color: #fff;
    background: $brand-primary;
}

%colorset-light {
    color: $gray-darker;
    background: $gray-lighter;
}

%colorset-white {
    color: $gray-darker;
    background: #fff;
}


/***************************** TYPOGRAPHY *****************************/

%text-headline {
    //font-size: 24px;
    font-weight: normal;
    text-transform: uppercase;
    //line-height: 24px;
}

%text-subheadline {
    //font-size: 16px;
    font-weight: normal;
    text-transform: uppercase;
}

%text-copyline {
    font-size: 14px;
    font-weight: bold;
}

%text-regular {
    font-size: 14px;
    font-weight: normal;
}

%text-small {
    font-size: 12px;
    font-weight: normal;
}

%text-fine-print {
    font-size: 10px;
    font-weight: normal;
}

%text-headline-category {
    @extend %text-small;
    font-family: $font-family-serif;
}

%text-navigation {
    font-size: 12px;
    font-weight: normal;
    text-transform: uppercase;
    line-height: 18px;
}

/******************************* BOXES ********************************/

%box {
    padding: $grid-gutter-width / 2;
}

%box-brand-primary {
    @extend %box;
    @extend %colorset-brand-primary;
}

%box-light {
    @extend %box;
    @extend %colorset-light;
}

%box-white {
    @extend %box;
    @extend %colorset-white;
    border: 1px solid $gray-lighter;
}