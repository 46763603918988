/***********************************************************************
* TABLES
***********************************************************************/

.div-table {
    background-color: $table-bg;
    margin-bottom: $line-height-computed;

    &,
    > .div-thead,
    > .div-tbody,
    > .div-tfoot {
        display: block;

        > .div-tr {
            @include container-fixed;
            display: block;
            padding-left: 0;
            padding-right: 0;

            > .div-th,
            > .div-th[class*="col-"],
            > .div-td,
            > .div-td[class*="col-"] {
                padding: $table-cell-padding;
            }

            > .div-th {
                font-weight: bold;
            }

            > .div-td,
            > .div-td[class*="col-"] {
                line-height: $line-height-base;
                vertical-align: top;
            }
        }

        > .div-tbody,
        > .div-tfoot {
            > .div-tr {
                > .div-th,
                > .div-td {
                    border-top: 1px solid $table-border-color;
                }
            }
        }
    }

    // Bottom align for column headings
    > .div-thead > .div-tr > .div-th {
        vertical-align: bottom;
        border-bottom: 2px solid $table-border-color;
    }

    // Remove top border from thead by default
    > .div-caption + .div-thead,
    > .div-colgroup + .div-thead,
    > .div-thead:first-child {
        > .div-tr:first-child {
            > .div-th,
            > .div-td {
                border-top: 0;
            }
        }
    }

    // Account for multiple tbody instances
    > .div-tbody + .div-tbody {
        border-top: 2px solid $table-border-color;
    }

    // Nesting
    //.div-table,
    //.table {
    //    background-color: $body-bg;
    //}

    .double-border {
        border-top: 3px double $gray-dark;
    }

    .bold {
        font-weight: bold;
    }
}

.div-table-bordered {
    border: 1px solid $table-border-color;

    &,
    > .div-thead,
    > .div-tbody,
    > .div-tfoot {

        > .div-tr {
            > .div-td {
                border: 1px solid $table-border-color;
            }
        }
    }
}

.div-table-striped {

    &,
    > .div-tbody {
        > .div-tr:nth-of-type(odd),
        > .div-tr.container-fluid:nth-of-type(odd) {
            background-color: $table-bg-accent;
        }
    }
}

// Condensed table w/ half padding
.div-table-condensed {
    &,
    > .div-thead,
    > .div-tbody,
    > .div-tfoot {
        > .div-tr {
            > .div-th,
            > .div-td {
                padding: $table-condensed-cell-padding;
            }
        }
    }
}




table.table {

    th {
        border-bottom: 1px solid #000;
    }

    td {
        border-bottom: 1px solid lighten($gray-base, 86.7%); // #ddd
    }

    .double-border {
        th,
        td {
            border-bottom: 3px double #000;
        }
    }

    no-border {
        th,
        td {
            border: 0 !important;
        }
    }
}

th,
td {

    &[align="left"],
    &.left {
        text-align: left;
    }

    &[align="right"],
    &.right {
        text-align: right;
    }

    &[align="center"],
    &.center {
        text-align: center;
    }
}

tr.zebra {
    th,
    td {
        background: $gray-lighter; // #efefef
    }
}