/***********************************************************************
* WIDGETS
***********************************************************************/

@import url(../../CC/Widget/resources/TopLink.css);
@import url(../../CC/Widget/resources/NotificationBox.css);
@import url(../../CC/Widget/resources/Rollator.css);

.loadingIcon {
    position: absolute;
    width: 16px;
    height: 16px;
    background: url(images/icon-loading.gif) left top no-repeat transparent;
    /*display:none;*/
}

/* NotificationBox */
.ccNotificationBox-content {
    width: 980px;
    padding: 20px;
    font-size: 1.2em;
    font-weight: bold;
    color: #fff;

    a {
        color: #fff !important;
        background: $gray;
        text-decoration:none;
        border-radius: 2px;
        padding: 2px 5px;

        &:hover {
            background: $brand-primary;
        }
    }

    .icon {
        position: relative;
        width: 45px;
        height: 30px;
        display: inline-block;
        vertical-align: middle;
        float: left;
        margin-right: 20px;
    }

    .icon-cart-add {
        background: url('images/notification-icon-cart-add.png') left top no-repeat transparent;
    }

    .icon-cart-remove {
        background: url('images/notification-icon-cart-remove.png') left top no-repeat transparent;
    }
}

.ccNotificationBox-closeBtn {
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-decoration: none !important;
    color: lighten($gray-base, 80%) !important; // #ccc
    background: transparent;
    border-radius: 10px;

    &:hover {
        background: $brand-primary;
        color: #fff !important;
    }
}

// rollator

.ccWidgetRollator {
    overflow: visible !important;
    margin-bottom: $grid-gutter-width * 2;

    &:focus {
        outline: none;
    }
}

.ccWidgetRollator-container {
    width: auto;
    height: 100%;
    overflow: visible;

    img {
        height: 100%;
    }
}

.ccWidgetRollator-item {
    height: 100%;
}

.ccWidgetRollator-pager {
    width: 100%;
    right: auto !important;
    left: auto !important;
    top: auto !important;
    bottom: -3em !important;
    text-align: center;
}

.ccWidgetRollator-pager-item {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    float: none;
    font-size: 0;
    background: $gray-lighter;

    &:hover {
        color: #fff;
        background: $brand-primary;
    }
}

.ccWidgetRollator-pager-item-active {
    color: #fff;
    background: $gray;
}

.CCWidgetTopLink {
    left: auto;
    right: $grid-gutter-width;
    bottom: $grid-gutter-width / 2;
    //width: 2em;
    padding: 0.5em 1em;
    background: $gray-lightest; // #ccc
    border-radius: 1em;
    font-size: 1em;
    color: $gray !important;

    &,
    &:active,
    &:link,
    &:hover,
    &:visited {
        text-decoration: none;
    }

    &:hover {
        color: #fff !important;
        background: $brand-primary;
    }
}