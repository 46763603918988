/***********************************************************************
* VARIABLES
***********************************************************************/

// colors

$gray-base:             #0f0f0f !default;   // bs default: #000
$gray-darkest:          #161515 !default;
$gray-darker:           #2b2a29 !default;   // bs default: #222
$gray-dark:             #403f3d !default;   // bs default: #333
$gray:                  #6a6966 !default;   // bs default: #555
$gray-light:            #95938e !default;   // bd default: #777
//$gray-lighter:          #bfbdb7 !default;   // bs default: #eee
$gray-lighter:          #e9e6df !default;   // bs default: #eee
$gray-lightest:         #f9f8f6 !default;

$brand-primary:         #9a1d85 !default;

// scaffolding

$body-bg:               $gray-lightest !default;  // #ddd (old: #dddcda)
//$body-bg:               #fff;

$font-family-sans-serif:    EvonikProkyon, "Trebuchet MS", Arial, Helvetica, sans-serif !default;
$font-family-serif:         EvonikAntares, "Trebuchet MS", "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", "Tahoma", Arial, sans-serif !default;

$font-size-base:          10px !default;

$headings-font-weight:    bold !default;

$border-radius-base:        0 !default;   // 4px
$border-radius-large:       0 !default;   // 6px
$border-radius-small:       0 !default;   // 3px

// forms

$input-bg:                       $gray-lightest !default;
$input-border:                   $gray-lighter !default;

// tables

$table-bg-accent:       $gray-lightest !default;