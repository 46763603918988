/***********************************************************************
* ORDER VIEW
***********************************************************************/

#order-check {
    margin-top: 2em;

    h3 {
        text-transform: uppercase;
        //border-bottom: 1px solid #000;
    }

    blockquote {
        font-size: 11px;
    }

    .button {
        width: 160px;
        text-align: left;
    }

    .table {
        span.label {
            display: inline-block;
            width: 7em;
        }
    }
}

#order-table-cart {
    margin-bottom: $grid-gutter-width;
    font-size: 11px;
    background: #fff;
}

#order-notice-box {
    border: 1px solid $brand-primary;

    h3 {
        color: #fff;
        background: $brand-primary;
    }

    h5 {
        color: $brand-primary;
    }

    h3,
    li {
        @extend %box;
        margin: 0;
    }

    li {
        border-top: 1px solid $gray-lighter; // #ccc
    }
}